// ==================================================
// Basic Styles
// ==================================================
* {
  box-sizing: border-box;
}

::selection {
  text-shadow: none;
  background: $section-color-background;
  color: $default-text-color;
}

html,
body {
  background-color: $bg-body;
  height: 100%;
  margin: 0;
  min-height: 100%;
}

body {
  color: $default-text-color;
  font: 20px / 24px $font;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;



  &.scroll {
    overflow: hidden;
  }
  &.alert-active {

  }
}

p:empty {
  display: none;
}
