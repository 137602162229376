footer.main {
  background-color: $brand-melot;
  position: relative;
  padding: 30px 0;

  @include mobile-landscape-lt {
    padding-top: 75px;
  }

 .footer-copy {
   display: flex;
   flex-direction: column;
   .disclaimer,
   .copyright {
      font-size: 20px;
      color: $brand-white;
      text-align: center;
      margin: 5px;
    }
 }



}
