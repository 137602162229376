// Common styles
.main-section {

    background-color: white;
    padding: 25px 0;
    h2,h3,h4,h5,h6,p {

    }
    body.active-scroll & {
        h2,h3,h4,h5,h6,p {

        }
    }
    &.quote {
        body.active-scroll & {

        h2,h3,h4,h5,h6,p,blockquote {

        }
        }
    }
    &.grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    &.slider {
        div {
            img {
                @include desktop-gt {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

}
section {
    position: relative;
    overflow: hidden;
    &.center {
        text-align: center;
        .cta-row {
            display: flex;
            justify-content: center;
            a {
                font-size: 20px;
                font-weight: 900;
                padding: 5px 20px;
                margin: 10px 30px;
                border-bottom: 4px solid $brand-melot;
                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}
