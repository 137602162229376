// ==================================================
// Brand
// ==================================================
$brand: #fff;
$brand-black: #000000;
$brand-white: #ffffff;
$brand-gray: #666666;
$brand-dark-gray: #333;
$brand-gray-light: #999999;

$brand-melot: #8B2330;
$brand-botticelli: #CEDFE9;
$brand-mineshaft: #343333;
$brand-mirage-opacity: rgba(0,0,0,0.42);
$brand-mirage: #121524;


$modal-background: rgba(0, 0, 0, .7);
$modal-content-background: $brand-white;
$brand-gray-border: #cccccc;

$section-color-background: $brand-white;

$highlight: #ffc;
$bg-body: $brand;
$content-area: #fff;

$header-gradient-c1: #BC8F39;
$header-gradient-c2: #E1C788;
$header-gradient-c3: #ffe8b4;

$brand-screen-width: 100vw;


// ========================================== ========
// Animations
// ==================================================
$base-duration: 150ms;
$base-timing: ease;

// ==================================================
// Grid information
// ==================================================
$max-grid-width: 1224px;

// ==================================================
// Typography
// ==================================================
$font: 'Noto Sans JP', sans-serif;
$default-text-color: $brand-mineshaft;

$footer-link-hover: $brand-gray-light;
$footer-link: $brand-gray;
$footer-icon: $brand-gray;

$container-padding: 20px;

$h1-size: 62px;
$h1-size-tablet: 2.6em;
$h2-size: 39px;
$h3-size: 32px;
$h4-size: 24px;
$h5-size: 24px;
$h6-size: 1.4em;

$h1-text: $brand-white;
$h2-text: $brand-mineshaft;
$h3-text: $brand-mineshaft;
$h4-text: $brand-mineshaft;
$h5-text: $brand-mineshaft;
$h6-text: $brand-mineshaft;

$h1-link: $brand-white;
$h2-link: $brand-mineshaft;
$h3-link: $brand-mineshaft;
$h4-link: $brand-mineshaft;
$h5-link: $brand-mineshaft;
$h6-link: $brand-mineshaft;

$h1-link-hover: $brand-white;
$h2-link-hover: $brand-mineshaft;
$h3-link-hover: $brand-mineshaft;
$h4-link-hover: $brand-mineshaft;
$h5-link-hover: $brand-mineshaft;
$h6-link-hover: $brand-mineshaft;

$p-text: $default-text-color;
$p-size: 20px;

$link: $brand-dark-gray;
$link-hover: $brand-dark-gray;
$rule: $brand-dark-gray;

$blockquote: #444;

$table-bg: #fff;
$table-bg-hover: darken($table-bg, 10%);
$td-text: #444;
$th-text: $brand-dark-gray;

// ==================================================
// Buttons
// ==================================================
$button-bg: #eee;
$button-bg-focus: #e5e5e5;

// ==================================================
// Forms
// ==================================================
$valid: none;
$invalid: #f0dddd; // red

$input-bg: #fff;
$input-border: #ccc;
$input-color: #a4a4a4;
$label-color: #444;

$input-size: 1.2em;
