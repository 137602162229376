/* Style the buttons that are used to open and close the accordion panel */
.accordion-section {
  h3 {
    text-align: center;
    margin: 25px 0;
  }
.accordion {

    background-color: $brand-botticelli;
    color: $brand-mineshaft;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    margin: 3px 0;
    font-family: $font;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 50px;
    font-size: 24px;
    font-weight: 400;
    &.active {
      margin: 3px 0 0;
    }
    i {
      position: absolute;
      left: 0;
      color: $brand-melot;
      font-size: 36px;
      padding: 0 10px;
    }
    /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
    .active, .accordion:hover {
      background-color: $brand-botticelli;
    }
    /* Style the accordion panel. Note: hidden by default */
  }
  .panel {
    padding: 0 18px 0 50px;
    background-color: $brand-botticelli;
    display: none;
    overflow: hidden;
    font-size: 20px;
    .modal-wrapper {
      display: flex;
      flex-direction: column;
      a {
        margin: 0 10px 0 0;
        font-weight: 700;
      }
    }
  }
}
