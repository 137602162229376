.grid {
    .card {
        max-height: 591px;
        width: 50%;
        overflow: hidden;
        @include desktop-lt {
            max-height: none;
        }
        @include tablet-portrait-lt {
            width: 100%;
        }
        &.img-card-one {
            background-image: url('/img/Schooner-A.png');
            background-size: cover;
            background-position: top center;
            @include tablet-portrait-lt {
                order: 1;
            }
            img {
                visibility: hidden;
            }
        }
        &.img-card-two {
            background-image: url('/img/Schooner-B.png');
            background-size: cover;
            background-position: top center;
            @include tablet-portrait-lt {
                order: 3;
            }
            img {
                visibility: hidden;
            }
        }
        &.copy {
            background-color: $brand-botticelli;
            padding: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;

        }
        &.first-copy {
            @include tablet-portrait-lt {
                order: 2;
            }
        }
        &.last-copy {
            @include tablet-portrait-lt {
                order: 4;
            }
        }
        .card-header {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            .icon {
                width: 74px;
                margin: 0 25px 10px;
            }
        }
    }
}