.alert-banner {
  display: none;

  @include tablet-landscape-lt {
    display: block;
    text-align: center;
    background-color: $brand-melot;
  }
  .application-button {
    color: $brand-white;
    font-weight: bold;
    padding: 9px;
    display: block;
  }
}