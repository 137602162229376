// ==================================================
// Responsive Mixins
// ==================================================
$mq-mobile-portrait: 320px !default;
$mq-mobile-landscape: 568px !default;
$mq-tablet-portrait: 800px !default;
$mq-tablet-landscape: 1024px !default;
$mq-desktop: 1382px !default;
$mq-desktop-2: 1180px !default;
$mq-interior-hero-max: 1440px !default;
$mq-hero-max: 1920px !default;
$mq-max-image: 2560px !default; // Both portrait and landscape

@mixin mobile {
  @media (max-width : $mq-mobile-landscape) {
    @content;
  }
}

// Everything up to and including the portrait width of the phone
// Since it's the smallest query it doesn't need a min
@mixin mobile-portrait {
  @media (max-width : $mq-mobile-portrait) {
    @content;
  }
}

// Everything up to and including the mobile portrait
@mixin mobile-portrait-lt {
  @media (max-width : $mq-mobile-portrait) {
    @content;
  }
}

// Everything above and including the mobile portrait
@mixin mobile-portrait-gt {
  @media (min-width : $mq-mobile-portrait) {
    @content;
  }
}

// Everthing larger than a portrait mobile up until mobile landscape
@mixin mobile-landscape {
  @media only screen and (min-width : $mq-mobile-portrait + 1) and (max-width : $mq-mobile-landscape) {
    @content;
  }
}

// Everything up to and including the mobile landscape width
@mixin mobile-landscape-lt {
  @media only screen and (max-width : $mq-mobile-landscape) {
    @content;
  }
}

// Everything above and including the mobile landscape width
@mixin mobile-landscape-gt {
  @media only screen and (min-width : $mq-mobile-landscape + 1) {
    @content;
  }
}

// Both the portrait and landscape width of the tablet
// Larger than a landscape mobile but less than or equal to a landscape tablet
@mixin tablet {
  @media only screen and (min-width : $mq-mobile-landscape + 1) and (max-width : $mq-tablet-landscape) {
    @content;
  }
}

// Everything larger than mobile landscape up until the portrait width of the tablet
@mixin tablet-portrait {
  @media only screen and (min-width : $mq-mobile-landscape + 1) and (max-width : $mq-tablet-portrait) {
    @content;
  }
}

// Everything below and including the portrait width of the tablet
@mixin tablet-portrait-lt {
  @media only screen and (max-width : $mq-tablet-portrait) {
    @content;
  }
}

// Everything above and including the portrait width of the tablet
@mixin tablet-portrait-gt {
  @media only screen and (min-width : $mq-mobile-landscape + 1) {
    @content;
  }
}

// Larger than portrait but less than or equal to the landscape width
@mixin tablet-landscape {
  @media only screen and (min-width : $mq-tablet-portrait + 1) and (max-width : $mq-tablet-landscape) {
    @content;
  }
}

// Up to and including the tablet landscape
@mixin tablet-landscape-lt {
  @media only screen and (max-width : $mq-tablet-landscape) {
    @content;
  }
}

// Everything larger than portrait tablet
@mixin tablet-landscape-gt {
  @media only screen and (min-width : $mq-tablet-portrait + 1) {
    @content;
  }
}

// Everything larger than a landscape tablet
@mixin desktop-gt {
  @media only screen and (min-width : $mq-tablet-landscape + 1) {
    @content;
  }
}

// Everything below and including the desktop
@mixin desktop-lt {
  @media only screen and (max-width : $mq-desktop) {
    @content;
  }
}

// Everything below and including the desktop
@mixin desktop-2-lt {
  @media only screen and (max-width : $mq-desktop-2) {
    @content;
  }
}

// Everything larger than a landscape tablet but less than or equal to the desktop
@mixin desktop {
  @media only screen and (min-width : $mq-tablet-landscape + 1) and (max-width : $mq-desktop) {
    @content;
  }
}

// Retina screens have a 1.5 pixel ratio, not 2
@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    @content;
  }
}

// Aspect Ratio Media Query call
@mixin sixteen-by-nine {
  @media screen and (min-aspect-ratio: 16/9) {
    @content;
  }
}
// Handle everything when the screen gets larger than the max image size
@mixin hero-max-image {
  @media only screen and (min-width : $mq-hero-max + 1) {
    @content;
  }
}

@mixin hero-max-image-gt {
  @media only screen and (min-width : $mq-hero-max + 1) {
    @content;
  }
}

@mixin hero-max-image-lt {
  @media only screen and (max-width : $mq-hero-max) {
    @content;
  }
}

@mixin interior-hero-max-image-gt {
  @media only screen and (min-width : $mq-interior-hero-max + 1) {
    @content;
  }
}

// Handle everything when the screen gets larger than the max image size
@mixin max-image {
  @media only screen and (min-width : $mq-max-image + 1) {
    @content;
  }
}
