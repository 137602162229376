/*************************************
*  Menu Styling
*************************************/

.menu {
    a {
        text-transform: uppercase;
        padding: 15px;
        font-size: 20px;
        line-height: 1;
        font-weight: 400;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}
