* {
  box-sizing: border-box;
}

::selection {
  text-shadow: none;
  background: #ffffff;
  color: #343333;
}

html,
body {
  background-color: #fff;
  height: 100%;
  margin: 0;
  min-height: 100%;
}

body {
  color: #343333;
  font: 20px/24px "Noto Sans JP", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body.scroll {
  overflow: hidden;
}

p:empty {
  display: none;
}


.container {
  margin: 0 auto;
  max-width: 1224px;
}


.container::after {
  clear: both;
  content: "";
  display: block;
}


.container.small {
  max-width: 960px;
}


.row {
  float: left;
  width: 100%;
  clear: both;
}

.firstcol {
  margin-left: 0;
}

.lastcol {
  margin-right: 0;
}


.col1 {
  width: calc(8.33333% - 21.66667px);
  float: left;
  margin-left: 20px;
}


.col2 {
  width: calc(16.66667% - 23.33333px);
  float: left;
  margin-left: 20px;
}


.col3 {
  width: calc(25% - 25px);
  float: left;
  margin-left: 20px;
}


.col4 {
  width: calc(33.33333% - 26.66667px);
  float: left;
  margin-left: 20px;
}


.col5 {
  width: calc(41.66667% - 28.33333px);
  float: left;
  margin-left: 20px;
}


.col6 {
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
}


.col7 {
  width: calc(58.33333% - 31.66667px);
  float: left;
  margin-left: 20px;
}


.col8 {
  width: calc(66.66667% - 33.33333px);
  float: left;
  margin-left: 20px;
}


.col9 {
  width: calc(75% - 35px);
  float: left;
  margin-left: 20px;
}


.col10 {
  width: calc(83.33333% - 36.66667px);
  float: left;
  margin-left: 20px;
}


.col11 {
  width: calc(91.66667% - 38.33333px);
  float: left;
  margin-left: 20px;
}


.col12 {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
}

@media only screen and (max-width: 800px) {
  
  .col1,
  .col10,
  .col11,
  .col12,
  .col2,
  .col3,
  .col4,
  .col5,
  .col6,
  .col7,
  .col8,
  .col9 {
    display: block;
    float: none;
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
    position: relative;
    width: 100%;
  }
}


.clearfix,
.row {
  zoom: 1;
}


.clearfix::after,
.row::after {
  clear: both;
}


.clearfix::after,
.clearfix::before,
.row::after,
.row::before {
  content: '\0020';
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}

fieldset {
  margin-bottom: 1em;
}

.form-container {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  /*margin-top:90px;
	margin-bottom: 30px;*/
  padding: 30px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 20px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.form-container .row {
  margin-bottom: 0;
}

/* label color */
.input-field label {
  color: #9c9c9c;
}

/* label focus color */
.input-field input[type=text]:focus + label {
  color: #9c9c9c;
}

/* label underline focus color */
.input-field input[type=text]:focus {
  border-bottom: 1px solid #9c9c9c;
  box-shadow: 0 1px 0 0 #9c9c9c;
}

/* valid color */
.input-field input[type=text].valid {
  border-bottom: 1px solid #9c9c9c;
  box-shadow: 0 1px 0 0 #9c9c9c;
  width: 100%;
}

/* invalid color */
.input-field input[type=text].invalid {
  border-bottom: 1px solid #9c9c9c;
  box-shadow: 0 1px 0 0 #9c9c9c;
  width: 100%;
}

/* icon prefix focus color */
.input-field .prefix.active {
  color: #9c9c9c;
}

input:not([type]):focus:not([readonly]),
input[type=text]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=date]:focus:not([readonly]),
input[type=datetime]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #009ddc;
  box-shadow: 0 1px 0 0 #009ddc;
}

input:not([type]):focus:not([readonly]) + label,
input[type=text]:focus:not([readonly]) + label,
input[type=password]:focus:not([readonly]) + label,
input[type=email]:focus:not([readonly]) + label,
input[type=url]:focus:not([readonly]) + label,
input[type=time]:focus:not([readonly]) + label,
input[type=date]:focus:not([readonly]) + label,
input[type=datetime]:focus:not([readonly]) + label,
input[type=datetime-local]:focus:not([readonly]) + label,
input[type=tel]:focus:not([readonly]) + label,
input[type=number]:focus:not([readonly]) + label,
input[type=search]:focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #009ddc;
}

.form-container .btn {
  background-color: #009ddc;
}

input[type=text],
input[type=email],
textarea[name=message] {
  width: 100%;
  margin: 10px 0;
}

textarea[name=message] {
  min-height: 150px;
}

.submitbtn {
  line-height: 48px;
  display: block;
  height: 48px;
  width: 100%;
}

.form-container h3 {
  font-size: 42px;
  letter-spacing: 0.1em;
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  top: -46px;
}

@media all and (max-width: 640px) {
  .form-container {
    position: static;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 100%;
    margin-top: 70px;
    margin-bottom: 20px;
  }
  .form-container h3 {
    font-size: 24px;
    top: -26px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1.2;
  margin-top: 0;
}

h1.uppercase,
h2.uppercase,
h3.uppercase,
h4.uppercase,
h5.uppercase,
h6.uppercase {
  text-transform: uppercase;
  letter-spacing: 6px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
}

h1 {
  color: #ffffff;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 62px;
  margin-bottom: 14px;
}

@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 2.6em;
  }
}

.path-frontpage h1.page-title {
  display: none;
}

h1 a {
  color: #ffffff;
}

h1 a:hover {
  color: #ffffff;
}


h2 {
  color: #343333;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 39px;
  margin-bottom: 10px;
}

@media (max-width: 568px) {
  
  h2 {
    line-height: 1em;
  }
}


h2 a {
  color: #343333;
}


h2 a:hover {
  color: #343333;
}

h2 + img.alignright,
h2 + img.alignleft,
h2 + img.alignnone,
h2 + img.aligncenter {
  margin-top: 0;
}

h2 + img + p {
  margin-top: 0;
}

h3 {
  color: #343333;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 32px;
  margin-bottom: 8px;
}

h3 a {
  color: #343333;
}

h3 a:hover {
  color: #343333;
}

h3 + img.alignright,
h3 + img.alignleft,
h3 + img.alignnone,
h3 + img.aligncenter {
  margin-top: 10px;
}

h4 {
  color: #343333;
  font-size: 24px;
  margin-bottom: 4px;
}

h4 a {
  color: #343333;
}

h4 a:hover {
  color: #343333;
}

h5 {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 15px;
}

h5 a {
  color: #343333;
}

h5 a:hover {
  color: #fff;
}

h6 {
  color: #343333;
  font-size: 1.4em;
}

h6 a {
  color: #343333;
}

h6 a:hover {
  color: #343333;
}

p {
  color: #343333;
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 20px;
}

p img {
  margin: 0;
}

p + img.alignright,
p + img.alignleft,
p + img.alignnone,
p + img.aligncenter {
  margin-top: 0;
}

hr {
  border: 1px solid #333;
  border-top: 1px solid #333;
  clear: both;
  height: 0;
  margin: 10px 0;
}

code,
pre {
  font-size: 15px;
}

blockquote {
  border-left: 10px solid #444;
  font-style: italic;
  color: #444;
  padding-left: 15px;
}

nav ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

ol {
  list-style: decimal;
}

ul.none {
  list-style: none;
}

ul.square {
  list-style: square outside;
}

ul.circle {
  list-style: circle outside;
}

ul.disc {
  list-style: disc outside;
}

dl dt {
  font-weight: bold;
  line-height: 1.2em;
}

dl dd {
  margin: 0 0 10px;
}

a,
a:visited,
a::selection {
  color: #333;
  outline: 0;
  text-decoration: none;
  background: transparent;
}

a:hover,
a:focus {
  color: #333;
  text-decoration: underline;
}

p a,
p a:visited {
  line-height: inherit;
}

em,
i {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 1.1em;
}

sub,
sup {
  font-size: .9em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img,
iframe,
object,
video {
  border: 0;
  height: auto;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
}

table {
  border-collapse: collapse;
  font-size: 20px;
  text-align: left;
  width: 100%;
}

table th {
  color: #333;
  font-weight: bold;
  padding: 2px 8px;
  text-transform: uppercase;
}

table td {
  border-top: 1px dotted #333;
  color: #444;
  padding: 2px 8px;
}

.ir {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em;
}

.irbr {
  display: none;
}

.hidden {
  display: none;
  visibility: hidden;
}

.dead-link {
  pointer-events: none;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clear {
  clear: both;
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}

.clearfix::before, .clearfix::after,
.row::before,
.row::after {
  content: '\0020';
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}

@keyframes hover-scale {
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1.15, 1.15);
  }
}

@keyframes left-to-right-reveal {
  0% {
    margin-left: -30px;
  }
  100% {
    margin-left: 20px;
  }
}

@keyframes banner-reveal-top-first {
  0% {
    margin-left: -1000px;
    visibility: hidden;
  }
  10% {
    margin-left: 0px;
    visibility: visible;
  }
  100% {
    visibility: hidden;
    display: none;
  }
}

@keyframes banner-reveal-top-second {
  0% {
    margin-left: -1000px;
    visibility: hidden;
  }
  10% {
    margin-left: 0px;
    visibility: visible;
  }
  100% {
    visibility: hidden;
    display: none;
  }
}

@keyframes banner-reveal-top-third {
  0% {
    margin-left: -1000px;
    visibility: hidden;
  }
  20% {
    margin-left: 0px;
    visibility: visible;
  }
  100% {
    visibility: hidden;
    display: none;
  }
}

@keyframes banner-reveal-middle {
  0% {
    display: block !important;
    margin-left: -1000px;
    visibility: hidden;
  }
  30% {
    margin-left: 0px;
    visibility: visible;
  }
  100% {
    margin-left: 0px;
    visibility: visible;
  }
}

@keyframes banner-reveal-bg {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 1;
  }
}

.main-section {
  background-color: white;
  padding: 25px 0;
}

.main-section.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1025px) {
  .main-section.slider div img {
    width: 100%;
    height: auto;
  }
}

section {
  position: relative;
  overflow: hidden;
}

section.center {
  text-align: center;
}

section.center .cta-row {
  display: flex;
  justify-content: center;
}

section.center .cta-row a {
  font-size: 20px;
  font-weight: 900;
  padding: 5px 20px;
  margin: 10px 30px;
  border-bottom: 4px solid #8B2330;
}

section.center .cta-row a:hover, section.center .cta-row a:active, section.center .cta-row a:focus {
  text-decoration: none;
}

header {
  max-width: 100%;
  max-height: 60px;
  z-index: 2;
  position: absolute;
  width: 100%;
}

header #navbar {
  overflow: hidden;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

header #navbar .logo {
  display: inline-block;
  padding: 9px 0;
}

header #navbar .logo img {
  height: auto;
  width: 100%;
  max-width: 250px;
  display: inline-block;
  padding: 0 13px;
}

@media only screen and (max-width: 800px) {
  header #navbar .logo img {
    width: 275px;
    height: auto;
    padding: 0 11px;
  }
}

header #navbar .menu {
  padding: 0 32px;
}

@media only screen and (max-width: 800px) {
  header #navbar .menu {
    display: none;
  }
}

header #navbar .menu a {
  color: white;
  font-family: "Noto Sans JP", sans-serif;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 1024px) {
  header #navbar .application-button-block {
    display: none;
  }
}

header #navbar .application-button-block .application-button {
  max-width: 226px;
  width: 100%;
  background-color: #8B2330;
  font-size: 20px;
  font-weight: 900;
  color: #ffffff;
  text-align: center;
  display: block;
  padding: 12px;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

header #navbar .application-button-block .application-button:hover, header #navbar .application-button-block .application-button:focus {
  text-decoration: none;
}

header #navbar .mobile-trigger {
  display: none;
}

header #navbar .mobile-trigger i {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  header #navbar .mobile-trigger {
    display: block;
  }
}

header.sticky.active {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

header.sticky.active #navbar .logo img.logo-light {
  display: block;
}

header.sticky.active #navbar .logo img.logo-dark {
  display: none;
}

@media only screen and (max-width: 800px) {
  header.sticky.active #navbar .logo img {
    width: 275px;
    height: auto;
  }
}

header.sticky.active #navbar .menu a {
  color: white;
}

header.sticky.active #navbar #mobile-menu-trigger {
  display: none;
}

@media only screen and (max-width: 800px) {
  header.sticky.active #navbar #mobile-menu-trigger {
    display: block;
  }
}

header.sticky.active #navbar #mobile-menu-trigger i {
  color: white;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
footer.main {
  background-color: #8B2330;
  position: relative;
  padding: 30px 0;
}

@media only screen and (max-width: 568px) {
  footer.main {
    padding-top: 75px;
  }
}

footer.main .footer-copy {
  display: flex;
  flex-direction: column;
}

footer.main .footer-copy .disclaimer,
footer.main .footer-copy .copyright {
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  margin: 5px;
}

.banner-section.fullscreen {
  height: 641px;
}

@media only screen and (max-width: 1024px) {
  .banner-section.fullscreen {
    height: 641px;
  }
}

@media only screen and (max-width: 800px) {
  .banner-section.fullscreen {
    height: 500px;
  }
}

.banner-section .bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.42);
}

.banner-section .background-image-holder {
  top: 0px;
  background-blend-mode: normal !important;
  position: relative;
  background-image: url("/img/banner-background.png");
  background-size: cover;
  background-position: top center;
}

@media (max-width: 568px) {
  .banner-section .background-image-holder {
    background-position: bottom center;
  }
}

.banner-section .background-image-holder img {
  visibility: hidden;
  width: 100%;
  height: 641px;
  /*@include desktop-lt {
        min-height: 0;
      }*/
}

@media only screen and (max-width: 1024px) {
  .banner-section .background-image-holder img {
    height: 641px;
    width: auto;
  }
}

@media only screen and (max-width: 800px) {
  .banner-section .background-image-holder img {
    height: 500px;
    width: auto;
  }
}

.banner-section .image-section {
  position: absolute;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 562px;
  width: 897px;
  left: -160px;
  bottom: 0;
}

.banner-section .v-align-transform {
  position: absolute;
  top: 50%;
  z-index: 2;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 100%;
  left: 0;
  right: 0;
}

.banner-section .v-align-transform .copy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 75px;
}

@media only screen and (max-width: 800px) {
  .banner-section .v-align-transform .copy-container {
    padding: 0 25px;
  }
}

@media (max-width: 568px) {
  .banner-section .v-align-transform .copy-container {
    align-items: flex-start;
  }
}

.banner-section .v-align-transform .copy-container .rule {
  border-bottom: 4px solid #ffffff;
  display: block;
  width: 298px;
}

.banner-section .v-align-transform .copy-container #contact-container-item {
  display: inline-block;
}

.banner-section .v-align-transform .schedule-tour-button {
  max-width: 240px;
  width: 100%;
  background-color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  display: block;
  padding: 12px 35px;
  text-align: center;
  border-radius: 4px;
  margin: 25px 0;
}

.banner-section .v-align-transform .schedule-tour-button:hover, .banner-section .v-align-transform .schedule-tour-button:focus {
  text-decoration: none;
}

.banner-section .v-align-transform .call-us-link {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.625rem;
  padding-left: 15px;
}

.banner-section .v-align-transform .call-us-link:hover, .banner-section .v-align-transform .call-us-link:focus {
  text-decoration: none;
}

.banner-section .v-align-transform .header-fade-1 {
  width: 100%;
}

.banner-section .v-align-transform .header-fade-1 h1 {
  font-family: "Noto Sans JP", sans-serif;
  letter-spacing: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media only screen and (max-width: 800px) {
  .banner-section .v-align-transform .header-fade-1 h1 {
    font-size: 35px;
  }
}

.banner-section .v-align-transform .header-fade-1 h6 {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 52px;
  padding: 0 20px;
  text-align: center;
  margin-bottom: 0;
  line-height: 1;
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
}

@media only screen and (max-width: 1180px) {
  .banner-section .v-align-transform .header-fade-1 h6 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 800px) {
  .banner-section .v-align-transform .header-fade-1 h6 {
    font-size: 25px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.banner-section .v-align-transform h1, .banner-section .v-align-transform h6 {
  color: white;
}

.banner-section .text-section {
  position: absolute;
  right: 30px;
  top: 135px;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .banner-section .text-section {
    width: 400px;
    top: 105px;
    right: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .banner-section .text-section {
    width: 300px;
    font-size: .8em;
  }
}

@media only screen and (max-width: 568px) {
  .banner-section .text-section {
    position: relative;
    width: 100%;
    top: auto;
    right: auto;
    padding: 0 20px;
  }
}

.banner-section h1,
.banner-section h2 {
  font-weight: 300;
}

.banner-section h2 {
  color: #999999;
}

.banner-section .align-bottom {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 2;
}

.alert-banner {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .alert-banner {
    display: block;
    text-align: center;
    background-color: #8B2330;
  }
}

.alert-banner .application-button {
  color: #ffffff;
  font-weight: bold;
  padding: 9px;
  display: block;
}

/*************************************
*  Menu Styling
*************************************/
.menu a {
  text-transform: uppercase;
  padding: 15px;
  font-size: 20px;
  line-height: 1;
  font-weight: 400;
}

.menu a:hover, .menu a:focus {
  text-decoration: none;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion-section h3 {
  text-align: center;
  margin: 25px 0;
}

.accordion-section .accordion {
  background-color: #CEDFE9;
  color: #343333;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  margin: 3px 0;
  font-family: "Noto Sans JP", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 50px;
  font-size: 24px;
  font-weight: 400;
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  /* Style the accordion panel. Note: hidden by default */
}

.accordion-section .accordion.active {
  margin: 3px 0 0;
}

.accordion-section .accordion i {
  position: absolute;
  left: 0;
  color: #8B2330;
  font-size: 36px;
  padding: 0 10px;
}

.accordion-section .accordion .active, .accordion-section .accordion .accordion:hover {
  background-color: #CEDFE9;
}

.accordion-section .panel {
  padding: 0 18px 0 50px;
  background-color: #CEDFE9;
  display: none;
  overflow: hidden;
  font-size: 20px;
}

.accordion-section .panel .modal-wrapper {
  display: flex;
  flex-direction: column;
}

.accordion-section .panel .modal-wrapper a {
  margin: 0 10px 0 0;
  font-weight: 700;
}

.grid .card {
  max-height: 591px;
  width: 50%;
  overflow: hidden;
}

@media only screen and (max-width: 1382px) {
  .grid .card {
    max-height: none;
  }
}

@media only screen and (max-width: 800px) {
  .grid .card {
    width: 100%;
  }
}

.grid .card.img-card-one {
  background-image: url("/img/Schooner-A.png");
  background-size: cover;
  background-position: top center;
}

@media only screen and (max-width: 800px) {
  .grid .card.img-card-one {
    order: 1;
  }
}

.grid .card.img-card-one img {
  visibility: hidden;
}

.grid .card.img-card-two {
  background-image: url("/img/Schooner-B.png");
  background-size: cover;
  background-position: top center;
}

@media only screen and (max-width: 800px) {
  .grid .card.img-card-two {
    order: 3;
  }
}

.grid .card.img-card-two img {
  visibility: hidden;
}

.grid .card.copy {
  background-color: #CEDFE9;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .grid .card.first-copy {
    order: 2;
  }
}

@media only screen and (max-width: 800px) {
  .grid .card.last-copy {
    order: 4;
  }
}

.grid .card .card-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.grid .card .card-header .icon {
  width: 74px;
  margin: 0 25px 10px;
}

.mobile-menu {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  display: table;
  text-align: center;
  opacity: 0;
  transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: scale(0);
}

.mobile-menu.active {
  opacity: 1;
  transform: scale(1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-menu.active a {
  text-transform: uppercase;
  padding: 15px;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
}

.mobile-menu .closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
}

.mobile-menu .closeButton i {
  cursor: pointer;
  font-size: 30px;
}

.modal {
  left: 50%;
  margin: -250px 0 0 -32%;
  opacity: 0;
  position: absolute;
  top: -50%;
  visibility: hidden;
  width: 65%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
}

@media screen and (min-width: 768px) {
  .modal {
    width: 600px;
    margin: -250px 0px 0px -18.2%;
    top: -50%;
  }
}

.modal:target {
  opacity: 1;
  top: 50%;
  visibility: visible;
}

.modal .footer {
  border-bottom: 1px solid #ffffff;
  border-radius: 5px 5px 0 0;
}

.modal .footer {
  border: none;
  border-top: 1px solid #ffffff;
  border-radius: 0 0 5px 5px;
}

.modal h2 {
  margin: 0;
  color: #333333;
}

.modal .btn {
  float: right;
}

.modal .header {
  height: 50px;
}

.modal .copy,
.modal .header,
.modal .footer {
  padding: 5px;
  color: #333333;
}

.modal .copy,
.modal .header,
.modal .footer,
.modal .modal-content {
  background: #ffffff;
  position: relative;
  z-index: 20;
  border-radius: 5px;
  color: #333333;
}

.modal .copy {
  background: #fff;
}

.modal .copy ul li a {
  color: #333333;
  text-decoration: none;
}

.modal .overlay {
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modal object {
  height: 100%;
  min-height: 770px;
}

@media (max-width: 568px) {
  .modal object {
    min-height: 469px;
  }
}

.modalcss-button {
  /* Structure */
  display: inline-block;
  zoom: 1;
  line-height: normal;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Firefox: Get rid of the inner focus border */
.modalcss-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.modalcss-button {
  font-family: inherit;
  font-size: 100%;
  padding: 0.5em 1em;
  color: #444;
  /* rgba not supported (IE 8) */
  color: rgba(0, 0, 0, 0.8);
  /* rgba supported */
  border: 1px solid #999;
  /*IE 6/7/8*/
  border: none rgba(0, 0, 0, 0);
  /*IE9 + everything else*/
  background-color: #E6E6E6;
  text-decoration: none;
  border-radius: 2px;
}

.modalcss-button-hover,
.modalcss-button:hover,
.modalcss-button:focus {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#1a000000',GradientType=0);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), color-stop(40%, rgba(0, 0, 0, 0.05)), to(rgba(0, 0, 0, 0.1)));
  background-image: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0.1));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1));
  background-image: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0.1));
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0.1));
}

.modalcss-button:focus {
  outline: 0;
}

.modalcss-button-active,
.modalcss-button:active {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset, 0 0 6px rgba(0, 0, 0, 0.2) inset;
  border-color: #0000;
}

.modalcss-button[disabled],
.modalcss-button-disabled,
.modalcss-button-disabled:hover,
.modalcss-button-disabled:focus,
.modalcss-button-disabled:active {
  border: none;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  filter: alpha(opacity=40);
  -khtml-opacity: 0.40;
  -moz-opacity: 0.40;
  opacity: 0.40;
  cursor: not-allowed;
  box-shadow: none;
}

.modalcss-button-hidden {
  display: none;
}

/* Firefox: Get rid of the inner focus border */
.modalcss-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.modalcss-button-primary,
.modalcss-button-selected,
a.modalcss-button-primary,
a.modalcss-button-selected {
  background-color: #0078e7;
  color: #fff;
}

.button-success,
.button-error,
.button-warning,
.button-secondary {
  color: white;
  border-radius: 4px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.button-success {
  background: #1cb841;
  /* this is a green */
}

.button-error {
  background: #ca3c3c;
  /* this is a maroon */
}

.button-warning {
  background: #df7514;
  /* this is an orange */
}

.button-secondary {
  background: #42b8dd;
  /* this is a light blue */
}

/* Responsive Video */
/*.video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
}
.video iframe,
.video object,
.video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}*/
/* Close Button */
.box3 {
  width: 25px;
  right: 0px;
  position: absolute;
  margin: 5px;
}

svg {
  display: block;
}

.close-x {
  stroke: black;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 5;
}
