header {
  max-width: 100%;
  max-height: 60px;
  z-index: 2;
  position: absolute;
  width: 100%;

  #navbar {
    overflow: hidden;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .logo {
      display: inline-block;
      padding: 9px 0;

      img {
        height: auto;
        width: 100%;
        max-width: 250px;
        display: inline-block;
        padding: 0 13px;

        @include tablet-portrait-lt {
          width: 275px;
          height: auto;
          padding: 0 11px;

        }
      }
    }
    .menu {
      padding: 0 32px;
      @include tablet-portrait-lt {
        display: none;
      }
      a {
        color: white;
        font-family: $font;
        transition: all 0.5s ease;
      }

    }
    .application-button-block {
      @include tablet-landscape-lt {
        display: none;
      }
      .application-button {
        max-width: 226px;
        width: 100%;
        background-color: $brand-melot;
        font-size: 20px;
        font-weight: 900;
        color: $brand-white;
        text-align: center;
        display: block;
        padding: 12px;
        text-align: center;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        &:hover,
        &:focus {
            text-decoration: none;
        }

      }
    }
    .mobile-trigger {
      display: none;
      i {
        color: $brand-white;
        font-size: 30px;
        cursor: pointer;
      }
      @include tablet-portrait-lt {
        display: block;
      }
    }
  }

  &.sticky.active{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    #navbar {

      .logo {
        img {
          &.logo-light {
            display: block;
          }
          &.logo-dark {
            display: none;
          }
          @include tablet-portrait-lt {
            width: 275px;
            height: auto;
          }
        }
      }
      .menu {
        a {
          color: white;
        }
      }
      #mobile-menu-trigger {
        display: none;
        @include tablet-portrait-lt {
          display: block;
        }
        i {
          color: white;
        }
      }
    }


  }

}
/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  //padding-top: 60px;
}
