.mobile-menu {
    background-color: $brand-white;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 100;
	height: 100%;
	width: 100%;
	display: table;
	text-align: center;
	opacity: 0;
	transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: scale(0);

  &.active {
    opacity: 1;
    transform: scale(1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
        text-transform: uppercase;
        padding: 15px;
        font-size: 24px;
        line-height: 1;
        font-weight: 700;
      }
  }

  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    i {
        cursor: pointer;
        font-size: 30px;
    }
  }
}