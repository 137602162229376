.modal {
	left: 50%;
	margin: -250px 0 0 -32%;
	opacity: 0;
	position: absolute;
	top: -50%;
	visibility: hidden;
	width: 65%;
	box-shadow: 0 3px 3px rgba(0,0,0,.25);
	box-sizing: border-box;
	transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;

    @media screen and (min-width: 768px) {
        width:600px;
		margin:-250px 0px 0px -18.2%;
		top: -50%;
    }
    &:target {
        opacity: 1;
	    top: 50%;
	    visibility: visible;
    }
    .footer {
        border-bottom: 1px solid $brand-white;
	    border-radius: 5px 5px 0 0;
    }
    .footer {
        border: none;
	    border-top: 1px solid $brand-white;
	    border-radius: 0 0 5px 5px;
    }
    h2 {
        margin: 0;
        color: #333333;
    }
    .btn {
        float: right;
    }
    .header {
        height: 50px;
    }
    .copy,
    .header,
    .footer {
        padding: 5px;
        color: #333333;
    }
    .copy,
    .header,
    .footer,
    .modal-content {
        background: $brand-white;
        position: relative;
        z-index: 20;
        border-radius: 5px;
        color: #333333;
    }
    .copy {
        background: #fff;
        ul {
            li {
                a {
                    color: #333333;
	                text-decoration: none;
                }
            }
        }
    }
    .overlay {
       // background-color: #000;
        background: rgba(0,0,0,.8);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
    }
    object {
        height: 100%;
        min-height: 770px;
        @include mobile {
            min-height: 469px;
        }
    }
}

.modalcss-button {
    /* Structure */
    display: inline-block;
    zoom: 1;
    line-height: normal;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* Firefox: Get rid of the inner focus border */
.modalcss-button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.modalcss-button {
    font-family: inherit;
    font-size: 100%;
    padding: 0.5em 1em;
    color: #444; /* rgba not supported (IE 8) */
    color: rgba(0, 0, 0, 0.80); /* rgba supported */
    border: 1px solid #999;  /*IE 6/7/8*/
    border: none rgba(0, 0, 0, 0);  /*IE9 + everything else*/
    background-color: #E6E6E6;
    text-decoration: none;
    border-radius: 2px;
}

.modalcss-button-hover,
.modalcss-button:hover,
.modalcss-button:focus {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#1a000000',GradientType=0);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), color-stop(40%, rgba(0,0,0, 0.05)), to(rgba(0,0,0, 0.10)));
    background-image: -webkit-linear-gradient(transparent, rgba(0,0,0, 0.05) 40%, rgba(0,0,0, 0.10));
    background-image: -moz-linear-gradient(top, rgba(0,0,0, 0.05) 0%, rgba(0,0,0, 0.10));
    background-image: -o-linear-gradient(transparent, rgba(0,0,0, 0.05) 40%, rgba(0,0,0, 0.10));
    background-image: linear-gradient(transparent, rgba(0,0,0, 0.05) 40%, rgba(0,0,0, 0.10));
}
.modalcss-button:focus {
    outline: 0;
}
.modalcss-button-active,
.modalcss-button:active {
    box-shadow: 0 0 0 1px rgba(0,0,0, 0.15) inset, 0 0 6px rgba(0,0,0, 0.20) inset;
    border-color: #0000;
}
.modalcss-button[disabled],
.modalcss-button-disabled,
.modalcss-button-disabled:hover,
.modalcss-button-disabled:focus,
.modalcss-button-disabled:active {
    border: none;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    filter: alpha(opacity=40);
    -khtml-opacity: 0.40;
    -moz-opacity: 0.40;
    opacity: 0.40;
    cursor: not-allowed;
    box-shadow: none;
}

.modalcss-button-hidden {
    display: none;
}

/* Firefox: Get rid of the inner focus border */
.modalcss-button::-moz-focus-inner{
    padding: 0;
    border: 0;
}

.modalcss-button-primary,
.modalcss-button-selected,
a.modalcss-button-primary,
a.modalcss-button-selected {
    background-color: rgb(0, 120, 231);
    color: #fff;
}
.button-success,
        .button-error,
        .button-warning,
        .button-secondary {
            color: white;
            border-radius: 4px;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        }

        .button-success {
            background: rgb(28, 184, 65); /* this is a green */
        }

        .button-error {
            background: rgb(202, 60, 60); /* this is a maroon */
        }

        .button-warning {
            background: rgb(223, 117, 20); /* this is an orange */
        }

        .button-secondary {
            background: rgb(66, 184, 221); /* this is a light blue */
        }
/* Responsive Video */
/*.video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
}
.video iframe,
.video object,
.video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}*/
/* Close Button */
.box3 {
	width: 25px;
	right: 0px;
	position: absolute;
	margin: 5px;
}
svg {
	display: block;
}
.close-x {
  stroke: black;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 5;
}