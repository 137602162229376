// Generic Animations
@keyframes hover-scale {

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1.15, 1.15);
  }
}

@keyframes left-to-right-reveal {

  0% {
    margin-left: -30px;
  }

  100% {
    margin-left: 20px;
  }
}

@keyframes banner-reveal-top-first {

  0% {
    margin-left: -1000px;
    visibility: hidden;
  }
  10% {
    margin-left: 0px;
    visibility: visible;
  }
  100% {

    visibility: hidden;
    display: none;
  }
}

@keyframes banner-reveal-top-second {

  0% {
    margin-left: -1000px;
    visibility: hidden;
  }
  10% {
    margin-left: 0px;
    visibility: visible;
  }
  100% {

    visibility: hidden;
    display: none;
  }
}
@keyframes banner-reveal-top-third {

  0% {
    margin-left: -1000px;
    visibility: hidden;
  }
  20% {
    margin-left: 0px;
    visibility: visible;
  }
  100% {

    visibility: hidden;
    display: none;
  }
}




@keyframes banner-reveal-middle {

  0% {
    display: block !important;
    margin-left: -1000px;
    visibility: hidden;
  }
  30% {
    margin-left: 0px;
    visibility: visible;
  }
  100% {
    margin-left: 0px;
    visibility: visible;

  }
}

@keyframes banner-reveal-bg {

  0% {

    opacity: 0;
  }
  50% {
    opacity: 1;

    visibility: visible;
  }
  100% {
    opacity: 1;


  }
}




