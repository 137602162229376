.banner-section {
  //z-index: 1001;
  //margin-top: -80px;

  &.fullscreen {
    height: 641px;
    @include tablet-landscape-lt {
      height: 641px;
    }
    @include tablet-portrait-lt {
      height: 500px;
    }
  }
  .bg-overlay {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background-color: $brand-mirage-opacity;

  }
  .background-image-holder {
    top: 0px;
    background-blend-mode: normal!important;
    position: relative;
    background-image: url('/img/banner-background.png');
    background-size: cover;
    background-position: top center;

    @include mobile {
      background-position: bottom center;
    }

    img {
      visibility: hidden;
      width: 100%;
      height: 641px;
      /*@include desktop-lt {
        min-height: 0;
      }*/
      @include tablet-landscape-lt {
        height: 641px;
        width: auto;

      }
      @include tablet-portrait-lt {
        height: 500px;
        width: auto;
      }
    }
  }

  .image-section {
    position: absolute;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 562px;
    width: 897px;
    left: -160px;
    bottom: 0;
  }
  .v-align-transform {
    position: absolute;
    top: 50%;
    z-index: 2;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 100%;
    left: 0;
    right: 0;


      .copy-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 75px;
        @include tablet-portrait-lt {
          padding: 0 25px;
        }
        @include mobile {
          align-items: flex-start;
        }
        .rule {
          border-bottom: 4px solid $brand-white;
          display: block;
          width: 298px;
        }

        #contact-container-item {
          display: inline-block;
        }
      }

      .schedule-tour-button {
        max-width: 240px;
        width: 100%;
        background-color: $brand-white;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        display: block;
        padding: 12px 35px;
        text-align: center;
        border-radius: 4px;
        margin: 25px 0;
        &:hover,
        &:focus {
            text-decoration: none;
        }
      }

      .call-us-link {
        color: $brand-white;
        font-weight: bold;
        font-size: 1.625rem;
        padding-left: 15px;
        &:hover,
        &:focus {
            text-decoration: none;
        }      
      }


      .header-fade-1 {
        width:100%;
        h1 {
          font-family: $font;
          letter-spacing: normal;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          @include desktop-2-lt {
            //font-size: 75px;
          }
          @include tablet-portrait-lt {
            font-size: 35px;

          }
          @include mobile {

          }
        }
        h6 {
          font-family: $font;
          font-size: 52px;
          padding: 0 20px;
          text-align: center;
          margin-bottom: 0;
          line-height: 1;
          display: flex;
            flex-direction: row;
            text-align: left;
            justify-content: center;
            align-items: flex-start;
            @include desktop-2-lt  {
              font-size: 35px;
            }
          @include tablet-portrait-lt {
            font-size: 25px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

        }

      }


    h1,h6 {
      color: white;
    }

  }

  .text-section {
    position: absolute;
    right: 30px;
    top: 135px;
    width: 100%;

    @include tablet-landscape-lt {
      width: 400px;
      top: 105px;
      right: 20px;
    }

    @include tablet-portrait-lt {
      width: 300px;
      font-size: .8em;
    }

    @include mobile-landscape-lt {
      position: relative;
      width: 100%;
      top: auto;
      right: auto;
      padding: 0 20px;
    }
  }

  h1,
  h2 {
    font-weight: 300;
  }

  h2 {
    color: $brand-gray-light;
  }
  .align-bottom {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 2;
  }

}