// ==================================================
// Non-semantic Helper Classes
// ==================================================
// For image replacement
.ir {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em;

  &br {
    display: none;
  }
}

// Hide from both screenreaders and browsers: h5bp.com/u
.hidden {
  display: none;
  visibility: hidden;
}

// When you dont want links clickable
.dead-link { pointer-events: none; }

// Hide only visually, but have it available for screenreaders: h5bp.com/v
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

// Hide visually and from screenreaders, but maintain layout
.invisible { visibility: hidden; }

// ==================================================
// clearing
// ==================================================
// You can also use a <br class="clear" /> to clear columns
.clear {
  clear: both;
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}

// Use clearfix class on parent to clear nested columns, or wrap each row of columns in a <div class="row">
.clearfix,
.row {

  &::before,
  &::after {
    content: '\0020';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
  }
}
